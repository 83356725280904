@import "abstracts/_abstracts";
.AppForm {
	form, &-holder {
		display: grid;
		grid-gap: 15px;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		@include media('<=xs') {
			grid-template-columns: 1fr;
		}
	}

	&-title {
		font-size: clamp(1.5rem, 3vw, 2rem);
		line-height: 1.25;
	}

	&-subTitle {
		border-top: 1px dashed var(--colorOutlines);
		padding-top: var(--spaceSm);
	}

	&-perex {
		margin-bottom: var(--spaceMd);
	}

	&-col {
		position: relative;

		&.is-inline {
			display: flex;
			flex-wrap: wrap;
		}
		&.is-right {
			justify-self: flex-end;
		}
	}

	&-label {
		font-size: var(--textSm);
		font-weight: 400;
		margin: 0 0 .2em;
		display: block;

		&.is-danger {
			color: var(--colorErrorsDefault);
		}

		&.is-required:after {
			content: '*';
			color: var(--colorErrorsDefault);
			margin-left: .15em;
		}

		&.is-inline {
			flex: 1;
			margin-left: var(--spaceSm);
		}

		& + .FormBubble {
			top: 0.25rem;
			transform: none;
		}
	}

	&-element {
		position: relative;

		> textarea, input {
			border-radius: 0.5rem;
		}
	}

	&-bubble {
		position: absolute;
		top: var(--sizeInputPaddingHorizontal);
		right: .5em;
		transform: translateY(-50%);

		&.is-inline {
			position: relative;
			top: 0;
			right: 0;
			transform: none;
			margin: .15em 0 0 var(--spaceSm);
		}

		select + & {
			right: 1.9em;
		}
	}

	&-gdpr {
		font-size: var(--textXs);
		padding-bottom: .4em;
	}

	&-underNotes {
		font-size: var(--textXs);

		span {
			color: var(--colorErrorsDefault);
		}
	}

	&Dynamic {
		&-button {
			text-align: right;
		}

		&-radio, &-checkbox {
			align-items: center;

			label {
				margin-bottom: 0;
			}
		}
	}
}